<template>
    <ul class="nav nav-tabs nav-fill custom-tabs">
        <li class="nav-item" v-for="(link, i) in links" :key="i">
            <router-link
                :to="link.path"
                class="nav-link d-flex gap-1 align-items-center"
                :class="{ active: link.path === $route.path }"
                >{{ $t(link.label) }}
                <i
                    class="bx bx-loader text-white bx-spin fs-5"
                    v-if="profile.routeTo == link.path && profile.loading"></i>
            </router-link>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            links: [
                {
                    label: 'game maintenance 1',
                    path: '/bet/game-settings/honorlink',
                },
                {
                    label: 'game maintenance 2',
                    path: '/bet/game-settings/mamahot',
                },
                // {
                //     label: 'game maintenance 2',
                //     path: '/bet/game-settings/kplay',
                // },
                
            ],
        };
    },
    computed: {
        ...mapGetters('auth', ['profile']),
    },
};
</script>
