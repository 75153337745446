<template>
    <Layout>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <GameMaintenanceTabs />
            <div class="button-items">
                <button
                    :class="`btn btn-success ${loading ? 'disabled' : ''}`"
                    @click="updateAll(1)">
                    {{ $t('full normal') }}
                </button>
                <button
                    :class="`btn btn-danger ${loading ? 'disabled' : ''}`"
                    @click="updateAll(0)">
                    {{ $t('full inspection') }}
                </button>
            </div>
        </div>
        <div class="card data">
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th scope="col">
                                    {{ $t('game type') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game name') }}
                                </th>
                                <th scope="col">
                                    {{ $t('status') }}
                                </th>
                                <th scope="col">
                                    {{ $t('change') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="$route.params.provider=='mamahot'">
                            <tr
                                v-for="(r, i) in mamahot_data.list"
                                :key="i"
                                >
                                <td class="text-center">
                                    <span
                                        :class="{
                                            'text-success': r.type == 'casino',
                                            'text-info': r.type == 'slots',
                                        }"
                                        >{{ $t(r.type) }}</span
                                    >
                                </td>
                                <td class="text-center">
                                    <span class="text-uppercase me-1">{{
                                        $t(r.name)
                                    }}</span
                                    >({{ $t($route.params.provider) }})
                                </td>
                                <td class="text-center">
                                    <div
                                        class="d-flex justify-content-center gap-2">
                                        <b-form-radio
                                            :name="`game-${i}`"
                                            :value="true"
                                            v-model="
                                                mamahot[r.type][r.setting_name][
                                                    'enabled'
                                                ]
                                            ">
                                            {{ $t('normal') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            :name="`game-${i}`"
                                            :value="false"
                                            v-model="
                                                mamahot[r.type][r.setting_name][
                                                    'enabled'
                                                ]
                                            ">
                                            {{ $t('inspection') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-outline-danger btn-sm"
                                        @click="
                                            updateStatus(
                                                r
                                            )
                                        ">
                                        {{ $t('change') }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr
                                v-for="(r, i) in data.list"
                                :key="i"
                                :class="{
                                    disabled:
                                        $route.params.provider === 'kplay' &&
                                        !kplay[r.type][r.setting_name][
                                            'prd_id'
                                        ],
                                }">
                                <td class="text-center">
                                    <span
                                        :class="{
                                            'text-success': r.type == 'casino',
                                            'text-info': r.type == 'slots',
                                        }"
                                        >{{ $t(r.type) }}</span
                                    >
                                </td>
                                <td class="text-center">
                                    <span class="text-uppercase me-1">{{
                                        $t(r.name)
                                    }}</span
                                    >({{ $t($route.params.provider) }})
                                </td>
                                <td class="text-center">
                                    <div
                                        v-if="
                                            $route.params.provider ===
                                            'honorlink'
                                        "
                                        class="d-flex justify-content-center gap-2">
                                        <b-form-radio
                                            :name="`game-${i}`"
                                            :value="true"
                                            v-model="
                                                honorlink[r.type][
                                                    r.setting_name
                                                ]['enabled']
                                            ">
                                            {{ $t('normal') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            :name="`game-${i}`"
                                            :value="false"
                                            v-model="
                                                honorlink[r.type][
                                                    r.setting_name
                                                ]['enabled']
                                            ">
                                            {{ $t('inspection') }}
                                        </b-form-radio>
                                    </div>
                                    <div
                                        v-if="
                                            $route.params.provider === 'kplay'
                                        "
                                        class="d-flex justify-content-center gap-2">
                                        <b-form-radio
                                            :name="`game-${i}`"
                                            :value="true"
                                            v-model="
                                                kplay[r.type][r.setting_name][
                                                    'enabled'
                                                ]
                                            ">
                                            {{ $t('normal') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            :name="`game-${i}`"
                                            :value="false"
                                            v-model="
                                                kplay[r.type][r.setting_name][
                                                    'enabled'
                                                ]
                                            ">
                                            {{ $t('inspection') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-outline-danger btn-sm"
                                        @click="
                                            updateStatus(
                                                r,
                                                kplay[r.type][r.setting_name][
                                                    'prd_id'
                                                ]
                                            )
                                        ">
                                        {{ $t('change') }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-show="data.list.length < 1">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import GameMaintenanceTabs from '@/components/game-maintenance-tabs';
import Pagination from '../../../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import convert from '../../../../../mixins/convert';
import datetime from '../../../../../mixins/datetime';
import number from '../../../../../mixins/number';
import ui from '../../../../../mixins/ui';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        GameMaintenanceTabs,
    },
    mixins: [datetime, number, convert, ui],
    data() {
        return {
            mamahot_data: {
                list: [
                    {
                        type: 'casino',
                        name: 'evolution',
                        setting_name: 'evolution',
                        provider: 'honor',
                        api_vendor: 'evolution',
                        status: 'normal',
                    },
                ]
            },
            data: {
                list: [
                    {
                        type: 'casino',
                        name: 'evolution',
                        setting_name: 'evolution',
                        provider: 'honor',
                        api_vendor: 'evolution',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'DreamGame',
                        setting_name: 'dreamgame',
                        provider: 'honor',
                        api_vendor: 'DreamGame',
                        status: 'inspection',
                    },
                    {
                        type: 'casino',
                        name: 'wm live',
                        setting_name: 'wmlive',
                        provider: 'honor',
                        api_vendor: 'WM Live',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'pragmatic play',
                        setting_name: 'pragmaticplaylive',
                        provider: 'honor',
                        api_vendor: 'PragmaticPlay Live',
                        status: 'inspection',
                    },
                    {
                        type: 'casino',
                        name: 'asia gaming',
                        setting_name: 'asiagaming',
                        provider: 'honor',
                        api_vendor: 'Asia Gaming',
                        status: 'inspection',
                    },
                    {
                        type: 'casino',
                        name: 'bota',
                        setting_name: 'bota',
                        provider: 'honor',
                        api_vendor: 'bota',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'Betgames.tv',
                        setting_name: 'betgamestv',
                        provider: 'honor',
                        api_vendor: 'Betgames.tv',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'Skywind Live',
                        setting_name: 'skywindlive',
                        provider: 'honor',
                        api_vendor: 'Skywind Live',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'pragmatic play',
                        setting_name: 'pragmaticplay',
                        provider: 'honor',
                        api_vendor: 'PragmaticPlay',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'Boongo',
                        setting_name: 'boongo',
                        provider: 'honor',
                        api_vendor: 'Booongo',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'cq9',
                        setting_name: 'cq9',
                        provider: 'honor',
                        api_vendor: 'CQ9',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'habanero',
                        setting_name: 'habanero',
                        provider: 'honor',
                        api_vendor: 'Habanero',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'redtiger',
                        setting_name: 'redtiger',
                        provider: 'honor',
                        api_vendor: 'redtiger',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'netent',
                        setting_name: 'netent',
                        provider: 'honor',
                        api_vendor: 'netent',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'Playson',
                        setting_name: 'playson',
                        provider: 'honor',
                        api_vendor: 'Playson',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'PlayStar',
                        setting_name: 'playstar',
                        provider: 'honor',
                        api_vendor: 'PlayStar',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'Dragoon Soft',
                        setting_name: 'dragoonsoft',
                        provider: 'honor',
                        api_vendor: 'Dragoon Soft',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'Blueprint Gaming',
                        setting_name: 'blueprintgaming',
                        provider: 'honor',
                        api_vendor: 'Blueprint Gaming',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'GameArt',
                        setting_name: 'gameart',
                        provider: 'honor',
                        api_vendor: 'GameArt',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'Wazdan',
                        setting_name: 'wazdan',
                        provider: 'honor',
                        api_vendor: 'Wazdan',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'EVOPLAY',
                        setting_name: 'evoplay',
                        provider: 'honor',
                        api_vendor: 'EVOPLAY',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'PlayPearls',
                        setting_name: 'playpearls',
                        provider: 'honor',
                        api_vendor: 'PlayPearls',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'Mobilots',
                        setting_name: 'mobilots',
                        provider: 'honor',
                        api_vendor: 'Mobilots',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'Nolimit City',
                        setting_name: 'nolimitcity',
                        provider: 'honor',
                        api_vendor: 'Nolimit City',
                        status: 'normal',
                    },
                    {
                        type: 'slots',
                        name: 'Thunderkick',
                        setting_name: 'thunderkick',
                        provider: 'honor',
                        api_vendor: 'Thunderkick',
                        status: 'normal',
                    },
                ],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
            },
            mamahot_data: {
                list: [
                    {
                        type: 'casino',
                        name: 'bbinlive',
                        setting_name: 'bbinlive',
                        provider: 'mamahot',
                        api_vendor: 'bbinlive',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'wmcasino',
                        setting_name: 'wmcasino',
                        provider: 'mamahot',
                        api_vendor: 'wmcasino',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'microgaming',
                        setting_name: 'microgaming',
                        provider: 'mamahot',
                        api_vendor: 'microgaming',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'dreamgaming',
                        setting_name: 'dreamgaming',
                        provider: 'mamahot',
                        api_vendor: 'dreamgaming',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'orientalgame',
                        setting_name: 'orientalgame',
                        provider: 'mamahot',
                        api_vendor: 'orientalgame',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'asiagaming',
                        setting_name: 'asiagaming',
                        provider: 'mamahot',
                        api_vendor: 'asiagaming',
                        status: 'normal',
                    },
                    {
                        type: 'casino',
                        name: 'bbinasia',
                        setting_name: 'bbinasia',
                        provider: 'mamahot',
                        api_vendor: 'bbinasia',
                        status: 'normal',
                    },
                ],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
            },
            create_modal: false,
            loading: false,
            honorlink: {
                casino: {
                    evolution: {
                        enabled: false,
                    },
                    dreamgame: {
                        enabled: false,
                    },
                    wmlive: {
                        enabled: false,
                    },
                    pragmaticplaylive: {
                        enabled: false,
                    },
                    asiagaming: {
                        enabled: false,
                    },
                    bota: {
                        enabled: false,
                    },
                    betgamestv: {
                        enabled: false,
                    },
                    skywindlive: {
                        enabled: false,
                    },
                    demo: {
                        enabled: false,
                    },
                },
                slots: {
                    pragmaticplay: {
                        enabled: false,
                    },
                    boongo: {
                        enabled: false,
                    },
                    cq9: {
                        enabled: false,
                    },
                    habanero: {
                        enabled: false,
                    },
                    redtiger: {
                        enabled: false,
                    },
                    netent: {
                        enabled: false,
                    },
                    playson: {
                        enabled: false,
                    },
                    playstar: {
                        enabled: false,
                    },
                    dragoonsoft: {
                        enabled: false,
                    },
                    blueprintgaming: {
                        enabled: false,
                    },
                    gameart: {
                        enabled: false,
                    },
                    wazdan: {
                        enabled: false,
                    },
                    evoplay: {
                        enabled: false,
                    },
                    playpearls: {
                        enabled: false,
                    },
                    mobilots: {
                        enabled: false,
                    },
                    nolimitcity: {
                        enabled: false,
                    },
                    thunderkick: {
                        enabled: false,
                    },
                    demo: {
                        enabled: false,
                    },
                },
            },
            kplay: {
                casino: {
                    evolution: {
                        prd_id: 1,
                        enabled: false,
                    },
                    dreamgame: {
                        prd_id: 6,
                        enabled: false,
                    },
                    wmlive: {
                        enabled: false,
                    },
                    pragmaticplaylive: {
                        prd_id: 10,
                        enabled: false,
                    },
                    asiagaming: {
                        enabled: false,
                    },
                    bota: {
                        prd_id: 18,
                        enabled: false,
                    },
                    betgamestv: {
                        enabled: false,
                    },
                    skywindlive: {
                        prd_id: 19,
                        enabled: false,
                    },
                    demo: {
                        enabled: false,
                    },
                },
                slots: {
                    pragmaticplay: {
                        prd_id: 200,
                        enabled: false,
                    },
                    boongo: {
                        prd_id: 217,
                        enabled: false,
                    },
                    cq9: {
                        prd_id: 220,
                        enabled: false,
                    },
                    habanero: {
                        prd_id: 201,
                        enabled: false,
                    },
                    redtiger: {
                        prd_id: 213,
                        enabled: false,
                    },
                    netent: {
                        prd_id: 214,
                        enabled: false,
                    },
                    playson: {
                        prd_id: 218,
                        enabled: false,
                    },
                    playstar: {
                        enabled: false,
                    },
                    dragoonsoft: {
                        prd_id: 215,
                        enabled: false,
                    },
                    blueprintgaming: {
                        enabled: false,
                    },
                    gameart: {
                        enabled: false,
                    },
                    wazdan: {
                        prd_id: 22,
                        enabled: false,
                    },
                    evoplay: {
                        prd_id: 225,
                        enabled: false,
                    },
                    playpearls: {
                        enabled: false,
                    },
                    mobilots: {
                        enabled: false,
                    },
                    nolimitcity: {
                        prd_id: 227,
                        enabled: false,
                    },
                    thunderkick: {
                        enabled: false,
                    },
                    demo: {
                        enabled: false,
                    },
                },
            },
            mamahot: {
                // balance: 0,
                // enabled: true
                casino: {
                    bbinlive: {
                        enabled: false,
                    },
                    wmcasino: {
                        enabled: false,
                    },
                    microgaming: {
                        enabled: false,
                    },
                    dreamgaming: {
                        enabled: false,
                    },
                    orientalgame: {
                        enabled: false,
                    },
                    asiagaming: {
                        enabled: false,
                    },
                    bbinasia: {
                        enabled: false,
                    },
                }
            }
        };
    },
    watch: {
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('setting', {
            settingGetList: 'getList',
            settingUpdate: 'update',
        }),
        async initList(p) {
            this.loading = true;
            const data = await this.settingGetList({
                type: this.$route.params.provider,
            });

            if(this.$route.params.provider=='mamahot') {
                this[this.$route.params.provider].casino.bbinlive.enabled = data[0][this.$route.params.provider].casino.bbinlive.enabled;
                this[this.$route.params.provider].casino.wmcasino.enabled = data[0][this.$route.params.provider].casino.wmcasino.enabled;
                this[this.$route.params.provider].casino.microgaming.enabled = data[0][this.$route.params.provider].casino.microgaming.enabled;
                this[this.$route.params.provider].casino.dreamgaming.enabled = data[0][this.$route.params.provider].casino.dreamgaming.enabled;
                this[this.$route.params.provider].casino.orientalgame.enabled = data[0][this.$route.params.provider].casino.orientalgame.enabled;
                this[this.$route.params.provider].casino.asiagaming.enabled = data[0][this.$route.params.provider].casino.asiagaming.enabled;
                this[this.$route.params.provider].casino.bbinasia.enabled = data[0][this.$route.params.provider].casino.bbinasia.enabled;
            } else {
                this[this.$route.params.provider].casino.evolution.enabled = data[0][this.$route.params.provider].casino.evolution.enabled;
                this[this.$route.params.provider].casino.dreamgame.enabled = data[0][this.$route.params.provider].casino.dreamgame.enabled;
                this[this.$route.params.provider].casino.wmlive.enabled = data[0][this.$route.params.provider].casino.wmlive.enabled;
                this[this.$route.params.provider].casino.pragmaticplaylive.enabled = data[0][this.$route.params.provider].casino.pragmaticplaylive.enabled;
                this[this.$route.params.provider].casino.asiagaming.enabled = data[0][this.$route.params.provider].casino.asiagaming.enabled;
                this[this.$route.params.provider].casino.bota.enabled = data[0][this.$route.params.provider].casino.bota.enabled;
                this[this.$route.params.provider].casino.betgamestv.enabled = data[0][this.$route.params.provider].casino.betgamestv.enabled;
                this[this.$route.params.provider].casino.skywindlive.enabled = data[0][this.$route.params.provider].casino.skywindlive.enabled;
                this[this.$route.params.provider].casino.demo.enabled = data[0][this.$route.params.provider].casino.demo.enabled;

                this[this.$route.params.provider].slots.pragmaticplay.enabled = data[0][this.$route.params.provider].slots.pragmaticplay.enabled;
                this[this.$route.params.provider].slots.boongo.enabled = data[0][this.$route.params.provider].slots.boongo.enabled;
                this[this.$route.params.provider].slots.cq9.enabled = data[0][this.$route.params.provider].slots.cq9.enabled;
                this[this.$route.params.provider].slots.habanero.enabled = data[0][this.$route.params.provider].slots.habanero.enabled;
                this[this.$route.params.provider].slots.redtiger.enabled = data[0][this.$route.params.provider].slots.redtiger.enabled;
                this[this.$route.params.provider].slots.netent.enabled = data[0][this.$route.params.provider].slots.netent.enabled;
                this[this.$route.params.provider].slots.playson.enabled = data[0][this.$route.params.provider].slots.playson.enabled;
                this[this.$route.params.provider].slots.playstar.enabled = data[0][this.$route.params.provider].slots.playstar.enabled;
                this[this.$route.params.provider].slots.dragoonsoft.enabled = data[0][this.$route.params.provider].slots.dragoonsoft.enabled;
                this[this.$route.params.provider].slots.blueprintgaming.enabled = data[0][this.$route.params.provider].slots.blueprintgaming.enabled;
                this[this.$route.params.provider].slots.gameart.enabled = data[0][this.$route.params.provider].slots.gameart.enabled;
                this[this.$route.params.provider].slots.wazdan.enabled = data[0][this.$route.params.provider].slots.wazdan.enabled;
                this[this.$route.params.provider].slots.evoplay.enabled = data[0][this.$route.params.provider].slots.evoplay.enabled;
                this[this.$route.params.provider].slots.playpearls.enabled = data[0][this.$route.params.provider].slots.playpearls.enabled;
                this[this.$route.params.provider].slots.mobilots.enabled = data[0][this.$route.params.provider].slots.mobilots.enabled;
                this[this.$route.params.provider].slots.nolimitcity.enabled = data[0][this.$route.params.provider].slots.nolimitcity.enabled;
                this[this.$route.params.provider].slots.thunderkick.enabled = data[0][this.$route.params.provider].slots.thunderkick.enabled;
                this[this.$route.params.provider].slots.demo.enabled = data[0][this.$route.params.provider].slots.demo.enabled;
            }


            this.loading = false;
        },
        async updateAll(status) {
            if (!this.isAuthorized()) return;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: status
                    ? this.$t('full normal')
                    : this.$t('full inspection'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const pl =
                    this.$route.params.provider === 'honorlink'
                        ? { honorlink: this.honorlink }:
                    this.$route.params.provider === 'kplay'
                        ? { kplay: this.kplay }
                        : { mamahot: this.mamahot };
                var fd =
                    this.$route.params.provider === 'honorlink' ||  this.$route.params.provider === 'mamahot'
                        ? this.objectToFormData(pl)
                        : this.objectToFormDataKPlay(pl);
                for (const pair of fd.entries()) {
                    fd.set(pair[0], status);
                }
                const res = await this.settingUpdate({
                    form_data: this.formDataToUrlEncoded(fd),
                });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('game settings updated')
                            : this.$t(res.data.message),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        async updateStatus(r, prd_id) {
            if (!this.isAuthorized()) return;
            if (!prd_id && this.$route.params.provider === 'kplay') return;
            var swal_html = `
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('game type')}<t/h>
                                    <td>${this.$t(r.type)}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('game name')}</th>
                                    <td>${this.$t(r.name)}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('status')}</th>
                                  
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                var fd = new FormData();

                fd.append(
                    `${this.$route.params.provider}[${r.type}][${r.setting_name}][enabled]`,
                    this[this.$route.params.provider][r.type][r.setting_name][
                        'enabled'
                    ]
                        ? 1
                        : 0
                );
                const res = await this.settingUpdate({
                    form_data: this.formDataToUrlEncoded(fd),
                });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('game settings updated')
                            : this.$t(res.data.message),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
         async updateMamahotStatus(r, prd_id) {
            if (!this.isAuthorized()) return;
            if (!prd_id && this.$route.params.provider === 'kplay') return;
            var swal_html = `
                        <table class="table table-sm table-bordered fs-6 mt-3">
                            <tbody>
                                <tr>
                                    <th>${this.$t('game type')}<t/h>
                                    <td>${this.$t(r.type)}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('game name')}</th>
                                    <td>${this.$t(r.name)}</td>
                                </tr>
                                <tr>
                                    <th>${this.$t('status')}</th>
                                    <td>${this.$t(
                                        this.mamahot['enabled']
                                            ? 'normal'
                                            : 'inspection'
                                    )}</td>
                                </tr>
                            </tbody>
                        </table>
                    `;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: swal_html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                var fd = new FormData();

                fd.append(
                    `mamahot[enabled]`,
                    this.mamahot['enabled']
                        ? 1
                        : 0
                );
                const res = await this.settingUpdate({
                    form_data: this.formDataToUrlEncoded(fd),
                });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('game settings updated')
                            : this.$t(res.data.message),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
    },
    mounted() {
        this.initList(1);
        this.$watch(
            () => this.$route.params,
            (n, o) => {
                if (this.$route.name == 'bet-game-settings') {
                    this.initList(1);
                }
            }
        );
    },
};
</script>
